<template>
  <v-dialog v-model="active" width="600">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-content>
          <v-list-item-title>Microsoft Account Security Info</v-list-item-title>
          <v-list-item-subtitle>Edit MFA options including recovery phone</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>Microsoft MFA</v-card-title>
      <v-card-text>
        <p>We are using the Microsoft login for our campus-wide single sign-on server. For certain critical applications (mainly Banner) this will require MFA, or Multi-Factor Authentication, run through the Microsoft Authenticator app or through a USB security key.</p>
        <p>We highly recommend setting up your phone as a secondary authentication, so when you get a new phone with the same phone number it will work. To add a phone number:</p>
        <ol>
          <li>Click the link below for Microsoft Security Info</li>
          <li>Click "Add sign-in method"</li>
          <li>Click on the dropdown and choose "Phone"</li>
          <li>Click Add</li>
          <li>Enter your cell phone number (if internation, make sure to choose the correct country code)</li>
          <li>Click Next</li>
          <li>You will receive a text with a code. Enter that code on the page and click Next.</li>
          <li>If everything works correctly, you should get a message that verification is complete. Click Done to close it.</li>
        </ol>
      </v-card-text>
      <v-card-actions>
        <v-btn href="https://mysignins.microsoft.com/security-info" target="_blank" text>Microsoft Security Info</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="active = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  setup (props) {
    const active = ref(false)

    return {
      active
    }
  }
}
</script>
